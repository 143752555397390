import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import _ from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { db } from "../../firebase";
import { getFactureDate } from "../../redux/slice/invoiceSlice";
import {
  Categorie,
  CategorieFormData,
  Content,
  Devis,
  DevisFormData,
  FactureEmise,
  FactureEmiseFormData,
  InvoiceData,
  MessageContact,
  ModelDevis,
  Notification,
  Releve,
  UserInfos,
} from "../../redux/types";
import {
  onHide,
  uploadCatImage,
  uploadFactureFile,
  uploadIcons,
} from "../utils";
import { getUsersAPi } from "./rest-api";

const firestore = getFirestore();
const functions = getFunctions();

export async function addUserAsync(data: Partial<UserInfos>) {
  const dbRef = collection(db, "users");
  // @ts-ignore
  data.created_at = serverTimestamp();
  return await addDoc(dbRef, data);
}
export async function createUserAsync(user: User, data: UserInfos) {
  const sendData = _.omit(data, ["password", "password_confirm"]);
  // @ts-ignore
  sendData.created_at = serverTimestamp();
  return await setDoc(doc(firestore, "users", user.uid), sendData);
}
export async function updateUserAsync(uid?: string, data?: Partial<UserInfos>) {
  if (!uid) return null;
  return await updateDoc(doc(firestore, "users", uid), data);
}
export function userListener(user: User, cb: (dzta: UserInfos | null) => any) {
  return onSnapshot(doc(firestore, "users", user.uid), async (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.data();

      cb({
        ...data,
        created_at: data.created_at?.toDate()?.toString(),
        uid: snapshot.id,
      } as UserInfos);
    } else {
      cb(null);
    }
  });
}

async function getModelsByUser(uid: string) {
  const docs = await getDocs(
    query(collection(firestore, "models"), where("userId", "==", uid))
  );

  const data: ModelDevis[] = [];
  docs.forEach((docData) => {
    const devis = {
      ...docData.data(),
      created_at: docData.data().created_at?.toDate()?.toString(),
      id: docData.id,
    } as ModelDevis;
    data.push(devis);
  });
  return data;
}

export async function devisListner(uid: string) {
  const docData = await getDoc(doc(firestore, "devis", uid));
  if (docData.exists()) {
    const devis = {
      ...docData.data(),
      created_at: docData.data().created_at?.toDate()?.toString(),
      id: docData.id,
    } as Devis;
    const user = await getUserById(devis.userId);
    if (user) {
      const models = await getModelsByUser(user.uid as string);
      return {
        devis,
        user,
        models,
      };
    }
  }

  return null;
}

export async function factureListner(uid: string) {
  const docData = await getDoc(doc(firestore, "factures", uid));
  if (docData.exists()) {
    const facture = {
      ...docData.data(),
      created_at: docData.data().created_at?.toDate()?.toString(),
      id: docData.id,
    } as any;
    if (facture.devis?.created_at?.toDate()) {
      // @ts-ignore
      facture.devis.created_at = facture.devis?.created_at
        ?.toDate?.()
        ?.getTime?.();
    }

    const user = await getUserById(facture.userId);
    if (user) {
      const models = await getModelsByUser(user.uid as string);
      return {
        facture: facture as FactureEmise,
        user,
        models,
      };
    }
  }

  return null;
}

export async function addDevisHtml(uid: string, html: string) {
  return await setDoc(doc(firestore, "htmls", uid), { html });
}

export async function updateDevis(uid: string, data: DevisFormData) {
  return await updateDoc(doc(firestore, "devis", uid), data);
}
export async function updateFacturePdf(uid: string, blob: Blob) {
  const file = await uploadFactureFile(blob);
  console.log("update pdf facture");
  await updateDoc(doc(firestore, "factures", uid), { file });
}

export async function updateFacture(uid: string, data: FactureEmiseFormData) {
  await updateDoc(doc(firestore, "factures", uid), data);
}

export async function getUserById(uid: string) {
  const docRef = await getDoc(doc(firestore, "users", uid));
  if (docRef.exists()) {
    return {
      ...docRef.data(),
      created_at: docRef.data().created_at?.toDate()?.toString(),
      uid: docRef.id,
    } as UserInfos;
  }
  return null;
}

export async function createUser(data) {
  const callable = httpsCallable(functions, "createUser");
  const user = await callable(data);

  return user;
}

export async function deleteUser(id) {
  const callable = httpsCallable(functions, "deleteUser");
  const user = await callable(id);

  return user;
}

export function usersListener(cb: (data: UserInfos[]) => any) {
  getUsersAPi().then((res) => cb(res));
}

export async function updateContent(data: string) {
  return updateDoc(doc(firestore, "contents", "cgu"), { html: data });
}

export function contentListener(cb: (dzta: Content) => void) {
  return onSnapshot(doc(firestore, "contents", "cgu"), async (snapshot) => {
    if (snapshot.exists()) {
      cb(snapshot.data() as Content);
    } else {
      const initData: Content = { html: "" };
      setDoc(doc(firestore, "contents", "cgu"), initData);
    }
  });
}

export async function updateUsersEmailComptable(
  oldEmail: string,
  newEmail: string
) {
  const q = query(
    collection(firestore, "users"),
    where("email_comptable", "==", oldEmail)
  );
  const promises: Promise<any>[] = [];
  const docs = await getDocs(q);
  docs.forEach((docRef) => {
    promises.push(
      updateDoc(doc(firestore, "users", docRef.id), {
        email_comptable: newEmail,
      })
    );
  });

  return Promise.all(promises);
}

export function usersComptableListener(
  email: string,
  cb: (data: UserInfos[]) => any,
  cb2: (data: string[]) => any
) {
  const q = query(
    collection(firestore, "users"),
    where("email_comptable", "==", email)
  );
  return onSnapshot(q, (snapshot) => {
    const users: UserInfos[] = [];
    const dels: string[] = [];
    snapshot.docChanges().forEach((change) => {
      if (change.type !== "removed") {
        const item = {
          ...change.doc.data(),
          uid: change.doc.id,
          created_at:
            change.doc.data().created_at?.toDate()?.getTime() ||
            new Date().getTime(),
        } as UserInfos;
        if (!item.deleted) {
          users.push(item);
        } else {
          dels.push(change.doc.id);
        }
      } else {
        dels.push(change.doc.id);
      }
    });
    if (users.length > 0) {
      cb(users);
    }
    if (dels.length > 0) {
      cb2(dels);
    }
  });
}

export function notificationListener(
  userId: string,
  cb: (data: Notification[]) => any
) {
  const q = query(collection(db, "notifications"), where("to", "==", userId));
  return onSnapshot(q, (snapshot) => {
    const notifications: Notification[] = [];
    const dels: string[] = [];
    snapshot.docChanges().forEach((change) => {
      if (change.type !== "removed") {
        const item = {
          ...change.doc.data(),
          uid: change.doc.id,
          created_at:
            change.doc.data().created_at?.toDate()?.getTime() ||
            new Date().getTime(),
        };
        notifications.push(item as Notification);
      } else {
        dels.push(change.doc.id);
      }
    });
    cb(notifications);
  });
}

export function CategorieListener(
  cb: (data: Categorie[], dels: string[]) => any
) {
  return onSnapshot(collection(db, "categories"), (snapshot) => {
    const categories: Categorie[] = [];
    const dls: string[] = [];
    snapshot.docChanges().forEach((change) => {
      if (change.type !== "removed") {
        const item = {
          ...change.doc.data(),
          id: change.doc.id,
        };
        categories.push(item as Categorie);
      } else {
        dls.push(change.doc.id);
      }
    });
    cb(categories, dls);
  });
}

export function iconListener(cb: (dzta: string[]) => any) {
  return onSnapshot(doc(firestore, "icons", "icons"), async (snapshot) => {
    if (snapshot.exists()) {
      const data = snapshot.data();
      cb(data.icons);
    }
  });
}

export async function updateNotification(
  uid: string,
  data: Partial<Notification>
) {
  return await updateDoc(doc(firestore, "notifications", uid), data);
}

export async function deleteNotification(uid: string) {
  return await deleteDoc(doc(firestore, "notifications", uid));
}

export async function deleteCategorie(uid: string) {
  const q = query(
    collection(firestore, "invoices"),
    where("categorie", "==", uid)
  );
  const docs = await getDocs(q);
  if (docs.size > 0) {
    return false;
  } else {
    await deleteDoc(doc(firestore, "categories", uid));
    return true;
  }
}

export function useAddCategorie(modalId: string, btnId: string) {
  const [loading, setLoading] = useState(false);
  const addCat = useCallback(
    async ({ image, ...data }: CategorieFormData, id?: string) => {
      setLoading(true);
      try {
        const sendData: any = { ...data };

        if (image) {
          sendData.image = await uploadCatImage(image);
        }
        if (!id) {
          await addDoc(collection(firestore, "categories"), sendData);
        } else {
          await updateDoc(doc(firestore, "categories", id), sendData);
        }
        setLoading(false);
        await Swal.fire({
          icon: "success",
          title: id
            ? "Catégorie modifiée avec succès"
            : "Catégorie ajoutée avec succès",
          showConfirmButton: false,
          iconColor: "#02B4C0",
          timer: 2000,
        }).then(async () => {
          setTimeout(() => onHide(modalId, btnId), 800);
        });
        return true;
      } catch (ex) {
        console.log(ex);
        await Swal.fire({
          icon: "error",
          title: id ? "Catégorie non modifiée" : "Catégorie non ajoutée",
          showConfirmButton: false,
          iconColor: "#02B4C0",
          timer: 2000,
        });
      }
      setLoading(false);
      return null;
    },
    []
  );
  return { addCat, loading };
}

export function useAddIcon(modalId: string, btnId: string) {
  const [loading, setLoading] = useState(false);
  const addCat = useCallback(async (icons: string[], files: File[]) => {
    setLoading(true);
    try {
      if (icons.length === 0) {
        setLoading(false);
        return;
      }
      const data = await uploadIcons(files);
      await updateDoc(doc(firestore, "icons", "icons"), {
        icons: [...data, ...icons],
      });
      setLoading(false);
      await Swal.fire({
        icon: "success",
        title: "Icons importés avec succès",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      }).then(async () => {
        setTimeout(() => onHide(modalId, btnId), 800);
      });
      return true;
    } catch (ex) {
      console.log(ex);
      await Swal.fire({
        icon: "error",
        title: "Icons non importés",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      });
    }
    setLoading(false);
    return null;
  }, []);
  return { addCat, loading };
}

export function useAddContent(cb: () => void) {
  const [updating, setUpdating] = useState(false);

  const onAdd = async (data: string) => {
    const wait = () => new Promise((r) => setTimeout(r, 1000));
    setUpdating(true);
    try {
      await updateContent(data);
      cb();
      await wait();
      Swal.fire({
        title: "Succès",
        text: "Données modifiées avec succés",
        icon: "success",
        iconColor: "#02B4C0",
      });
    } catch (ex) {
      Swal.fire({
        icon: "error",
        title: "Données non modifiées",
        showConfirmButton: false,
        timer: 5000,
        iconColor: "#02B4C0",
      });
    }
    setUpdating(false);
  };
  return {
    loading: updating,
    onAdd,
  };
}

export function messageContactListener(cb: (data: MessageContact[]) => void) {
  return onSnapshot(
    collection(firestore, "contacts"),
    async (querySnapshot) => {
      const data: MessageContact[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type !== "removed") {
          const dta: unknown = {
            ...change.doc.data(),
            id: change.doc.id,
            created_at:
              change.doc.data().created_at?.toDate()?.getTime() ||
              new Date().getTime(),
          };
          data.push(dta as MessageContact);
        }
      });
      cb(data);
    }
  );
}

export async function responseMessageContact(id: string, response: string) {
  const item = await getDoc(doc(firestore, "contacts", id));
  if (item.exists()) {
    const data = item.data() as MessageContact;
    const responses = data.responses
      ? [...data.responses, response]
      : [response];
    await updateMessageContact(id, { responses });
    sendResponseContactFunction({
      email: data.email,
      response: response,
      subject: data.sujet ?? data.message,
    });
  }
  return null;
}
export async function updateMessageContact(
  id: string,
  data: Partial<MessageContact>
) {
  return updateDoc(doc(firestore, "contacts", id), data);
}

type SendResponseMail = {
  email: string;
  subject: string;
  response: string;
};

export function sendResponseContactFunction(data: SendResponseMail) {
  const callable = httpsCallable(functions, "sendResponseContact");
  return callable(data);
}

export function useResponseContact(cb: () => void) {
  const [loading, setLoading] = useState(false);

  const onResponse = useCallback(
    async (id: string, response: string) => {
      setLoading(true);
      try {
        await responseMessageContact(id, response);
        setTimeout(() => cb(), 400);
        setTimeout(() => {
          Swal.fire({
            icon: "success",
            title: "Réponse envoyé avec succés",
            showConfirmButton: false,
            timer: 5000,
            iconColor: "#02B4C0",
          });
          setLoading(false);
        }, 800);
      } catch (ex) {
        setTimeout(() => cb(), 400);
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "Réponse non envoyé",
            showConfirmButton: false,
            timer: 5000,
            iconColor: "#02B4C0",
          });
          setLoading(false);
        }, 800);
      }
    },
    [cb]
  );

  return {
    loading,
    onResponse,
  };
}

export async function getReleves(ids: string[]) {
  const list = _.chunk(ids, 10);
  const data: Releve[] = [];
  for (const listIds of list) {
    const docs = await getDocs(
      query(collection(firestore, "releves"), where("userId", "in", listIds))
    );
    docs.docs.forEach((doc) => {
      const dta: any = {
        ...doc.data(),
        uid: doc.id,
      };
      data.push(dta as Releve);
    });
  }
  return data.sort((a, b) => b.date - a.date);
}

export function getExportData(
  invoices: InvoiceData[],
  categories: Categorie[]
) {
  const map = new Map(categories.map((c) => [c.id, c]));
  return invoices.map((el) => {
    const cat = el.categorie ? map.get(el.categorie) : null;
    return {
      Date: moment(getFactureDate(el)).format("DD/MM/YYYY"),
      Client: el?.user?.prenom + " " + el?.user?.nom,
      Journal: "HA",
      Compte: cat ? cat?.compte : "",
      "N°facture": el?.number,
      Libellé: el?.commerce_name,
      Débit: el.type_depense === "Dépense" ? el?.montant_ttc : "0",
      Crédit: el.type_depense !== "Dépense" ? el?.montant_ttc : "0",
      // "Montant HT": el?.montant_ht,
      // "Montant TVA": el?.tva,
      // "Montant TTC": el?.montant_ttc,
    };
  });
}

export function getExportDataFacture(invoices: FactureEmise[]) {
  return invoices.map((el) => {
    return {
      Date: moment(el.created_at).format("DD/MM/YYYY"),
      Client: el.prenom_client + " " + el?.nom_client,
      Journal: "HA",
      Compte: "",
      "N°facture": el.numero,
      Libellé: el.type_operation,
      Débit: "0",
      Crédit: el.total_ttc,
      // "Montant HT": el?.montant_ht,
      // "Montant TVA": el?.tva,
      // "Montant TTC": el?.montant_ttc,
    };
  });
}
