import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../redux/store";
import { InvoiceData } from "../../../../../redux/types";
import ExcelExport from "../../../../../utils/Excelexport";
import { getExportData } from "../../../../../utils/api/user.api";
import FacturesTable from "../../../../tables/admin/FacturesTable/FacturesTable";

function TabsFacture({ state }) {
  const invoices = useAppSelector((s) => s.invoices);
  const [data, setData] = useState<InvoiceData[]>([]);
  const categories = useAppSelector((s) => s.categories);
  useEffect(() => {
    setData(invoices.filter((i) => i.user?.uid === state.uid));
  }, [invoices]);

  return (
    <div className="bg-white p-3">
      <div className="flex-sb align-items-center mb-4">
        <div className="tab-dash-admin-filter-container"></div>
        <ExcelExport
          getFileData={() => getExportData(data, categories)}
          fileName={`Factures_${new Date().getTime()}`}
        />
      </div>
      <FacturesTable data={data} isLoading={false} func={() => null} />
    </div>
  );
}

export default TabsFacture;
