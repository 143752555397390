import Skeleton from "react-loading-skeleton";
import { NavLink } from "react-router-dom";
import Factures from "../../../../assets/icons/facture.png";
import TvaCollecte from "../../../../assets/icons/tva-collecte.png";
import TvaEmise from "../../../../assets/icons/tva-emise.png";
import Users from "../../../../assets/icons/users.png";
import { useAppSelector } from "../../../../redux/store";
import { useDashboard } from "../../../../utils/api/dashboard.api";
import { formatNumber } from "../../../../utils/utils";
import FacturesRecentesTable from "../../../tables/admin/FacturesRecentesTable/FacturesRecentesTable";
import "./DashboardAdmin.css";

const DashboardAdmin = () => {
  const invoices = useAppSelector((s) => s.invoices);
  const factures = useAppSelector((s) => s.factures);
  const users = useAppSelector((s) => s.users);
  const { data, loading: isLoading } = useDashboard(users, invoices, factures);
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Dashboard</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="row">
            {isLoading ? (
              <CardSkeleton />
            ) : (
              <>
                <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2">
                  <div className="item-stat-dashboad-view">
                    <div className="content-stat-dashboard-view">
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Clients{" "}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {formatNumber(data.total_user_count)}
                          </p>
                        </div>
                      </div>
                      <div className="content-item-icon-stat-dashboard-view">
                        <img
                          src={Users}
                          alt="icon"
                          className="icon-item-stat-dashboad-view"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2">
                  <div className="item-stat-dashboad-view">
                    <div className="content-stat-dashboard-view">
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Total factures emises
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {formatNumber(data.total_facture_emise)}
                          </p>
                        </div>
                      </div>
                      <div className="content-item-icon-stat-dashboard-view">
                        <img
                          src={Factures}
                          alt="icon"
                          className="icon-item-stat-dashboad-view"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2">
                  <div className="item-stat-dashboad-view">
                    <div className="content-stat-dashboard-view">
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            {" "}
                            Total TVA collectées{" "}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {formatNumber(data.total_tva_recuperable)}
                          </p>
                        </div>
                      </div>
                      <div className="content-item-icon-stat-dashboard-view">
                        <img
                          src={TvaCollecte}
                          alt="icon"
                          className="icon-item-stat-dashboad-view"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2">
                  <div className="item-stat-dashboad-view">
                    <div className="content-stat-dashboard-view">
                      <div className="content-text-item-stat-dashboad-view ms-3">
                        <div className="content-item-stat-dashboad-view mt-2">
                          <p className="titre-item-stat-dashboad-view mb-1">
                            Total TVA émises{" "}
                          </p>
                        </div>
                        <div className="icon-item-stat-dashboad-view">
                          <p className="chiffre-item-stat-dashboad-view mb-1">
                            {formatNumber(data.total_tva_emise)}
                          </p>
                        </div>
                      </div>
                      <div className="content-item-icon-stat-dashboard-view">
                        <img
                          src={TvaEmise}
                          alt="icon"
                          className="icon-item-stat-dashboad-view"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="bg-white p-3 mt-4">
            <div className="flex-sb align-items-center mb-4">
              <div className="container-title-card-dashbord-view">
                <h3 className="title-card-dashbord-view">Factures recentes</h3>
              </div>
              <div className="content-btn-see-more">
                <NavLink to="/admin/factures" className="btn btn-see-more">
                  Voir toutes les factures
                </NavLink>
              </div>
            </div>
            <FacturesRecentesTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;

export const CardSkeleton = () => {
  return (
    <>
      {Array(4)
        .fill(4)
        .map((item, i) => (
          <div className="col-lg-3 col-md-12 mb-3 dis-flex px-md-2" key={i}>
            <div className="item-stat-dashboad-view">
              <div className="content-item-stat-dashboad-view">
                <Skeleton
                  className="w-full h-full lh-2 titre-item-stat-dashboad-view"
                  width={100}
                  height={15}
                />

                <Skeleton
                  className="w-full h-full lh-2 chiffre-item-stat-dashboad-view"
                  width={80}
                  height={`100%`}
                />
              </div>

              <Skeleton
                className="w-full h-full lh-2 icon-item-stat-dashboad-view"
                circle
                width={50}
                height={50}
              />
            </div>
          </div>
        ))}
    </>
  );
};
