import { useMemo, useState } from "react";
import { ImDownload3 } from "react-icons/im";
import Swal from "sweetalert2";
import { onHide } from "../../../utils/utils";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FactureEmise, FactureEmiseFormData } from "../../../redux/types";
import {
  addDevisHtml,
  factureListner,
  updateFacture,
} from "../../../utils/api/user.api";
import { formatAmout } from "../../../utils/formatter";
import { generateFacture } from "../../guest/devis-generator";
import { getLastModel } from "../../guest/hooks";
import CloseModalBtn from "../../shared/CloseModalBtn";

function DetailFactureEmiseComptabiliteModal({
  facture,
  func,
  modalId,
  btnId,
  readOnly,
}: {
  facture: FactureEmise;
  func: () => void;
  modalId: string;
  btnId: string;
  readOnly: boolean;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeTraiter = async (istraited: boolean) => {
    setIsLoading(true);
    try {
      await updateFacture(facture?.uid, {
        statut: istraited ? "Signé" : "Soldée",
      });
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: "Modification réussie!",
        showConfirmButton: false,
        iconColor: "#02B4C0",
        timer: 2000,
      }).then(async () => {
        func();
        onHide(modalId, btnId);
        setIsLoading(false);
      });
    } catch (ex) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Une erreur est survenue!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const hasEch = facture.acoumpt || facture.echeance1 || facture.echeance2;

  const DATA = [
    { title: "Numéro", value: facture.numero },
    { title: "Statut", value: facture.statut },
    { title: "Numéro client", value: facture.numero_client },
    {
      title: "Client",
      value: `${facture.prenom_client} ${facture.nom_client}`,
    },
    {
      title: "Créer le",
      value: moment(facture.created_at).format("DD/MM/YYYY"),
    },
    { title: "Montant HT", value: formatAmout(facture.total_ht_after_remise) },
    { title: "Montant TTC", value: formatAmout(facture.total_ttc) },
    { title: "Montant TVA", value: formatAmout(facture.total_tva) },
  ];

  return (
    <div>
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content py-2">
          <div className="modal-header d-flex justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <h1 className="admin-modal-title text-start">
                Détails de la facture
              </h1>
              {!readOnly && (
                <>
                  {facture?.statut === "Soldée" ? (
                    <div className="statut-facture-valide">Validé</div>
                  ) : (
                    <div className="statut-facture-a-traiter">A traiter</div>
                  )}
                </>
              )}
            </div>
            <CloseModalBtn />
          </div>
          <div className="container-form">
            <div className="row py-4">
              <div className="col-md-7">
                <div className="container-info-facture-comptabilite">
                  {DATA.map((it) => (
                    <div className="content-info-facture-comptabilite-item">
                      <div className="content-info-facture-comptabilite-libelle">
                        <p className="info-facture-comptabilite-libelle">
                          {it.title}
                        </p>
                      </div>
                      <div className="content-info-facture-comptabilite-value">
                        <p className="info-facture-comptabilite-value">
                          {it.value}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-5">
                <div className="content-document-facture">
                  <div className="containet-btn-download-doc-facture mt-3">
                    <span
                      title="download"
                      onClick={() => {
                        if (!facture.file) {
                          factureListner(facture.uid).then(async (res) => {
                            if (res) {
                              const htmlContent = await generateFacture(
                                res.facture,
                                getLastModel(res.models),
                                res.user,
                                "",
                                res.facture.date_facture
                              );
                              await addDevisHtml(res.facture.id, htmlContent);
                            }
                            window.open(
                              `https://api.fastfacture.com/api/generate-pdf?uid=${facture.id}`,
                              "_blank"
                            );
                          });
                        } else {
                          window.open(
                            `https://api.fastfacture.com/api/generate-pdf?uid=${facture.id}`,
                            "_blank"
                          );
                        }
                      }}
                      className="btn btn-download-doc-facture"
                      rel="noreferrer"
                    >
                      <ImDownload3 />
                      <span className="ps-2">Télécharger</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-btn-modal row mt-4 px-1">
              {hasEch && (
                <div>
                  <EcheTabel
                    item={facture}
                    readOnly={readOnly}
                    onDone={() => {
                      Swal.fire({
                        icon: "success",
                        title: "Modification réussie!",
                        showConfirmButton: false,
                        iconColor: "#02B4C0",
                        timer: 2000,
                      }).then(async () => {
                        func();
                        onHide(modalId, btnId);
                      });
                    }}
                  />
                </div>
              )}
              {!hasEch && !readOnly && (
                <div className="col-md-4">
                  <button
                    className={
                      facture.statut === "Soldée"
                        ? "btn auth-submit-btn-red"
                        : "btn auth-submit-btn"
                    }
                    onClick={() =>
                      handleChangeTraiter(facture.statut === "Soldée")
                    }
                    disabled={isLoading}
                  >
                    {!isLoading ? (
                      facture.statut === "Soldée" ? (
                        "Marquer comme à traiter"
                      ) : (
                        "Marquer comme traité"
                      )
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>Enregistrement...</span>
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailFactureEmiseComptabiliteModal;

function EcheTabel({
  item,
  onDone,
  readOnly,
}: {
  item: FactureEmise;
  onDone: () => void;
  readOnly: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  const [accompt, setAccompt] = useState(item.acoumpt);
  const [ech1, setEch1] = useState(item.echeance1);
  const [ech2, setEch2] = useState(item.echeance2);

  const echeances = useMemo(() => {
    const data = [accompt, ech1, ech2].filter((i) => !!i);
    return data;
  }, [accompt, ech1, ech2]);
  const onSubmit = async () => {
    setLoading(true);
    const data: FactureEmiseFormData = {};
    let ok = true;
    if (accompt) {
      data.acoumpt = accompt;
      if (!data.acoumpt.paid_date) ok = false;
    }
    if (ech1) {
      data.echeance1 = ech1;
      if (!data.echeance1.paid_date) ok = false;
    }
    if (ech2) {
      data.echeance2 = ech2;
      if (!data.echeance2.paid_date) ok = false;
    }
    try {
      if (ok) data.statut = "Soldée";
      else {
        if (item.statut === "Soldée") {
          data.statut = "Signé";
        }
      }
      await updateFacture(item.id, data);
      onDone();
    } catch (ex) {}
    setUpdated(false);
    setLoading(false);
  };

  const columns = [
    {
      dataField: "label",
      text: "Label",
    },
    {
      dataField: "date",
      text: "Date échéance",
      formatter: (cell: any, row: any) => cell,
    },
    {
      dataField: "montant",
      text: "Montant échéance",
      formatter: (cell: any, row: any) => formatAmout(cell),
    },
    {
      dataField: "paid_date",
      text: "Échéance payée le",
      formatter: (cell: any, row: any) => {
        return (
          <div>
            <input
              placeholder="__ /__ /____"
              type="date"
              readOnly={readOnly}
              value={
                row.paid_date
                  ? moment(row.paid_date, "DD/MM/YYYY").format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                if (e.target.value) {
                  setUpdated(true);
                  const paid_date = moment(e.target.value).format("DD/MM/YYYY");
                  if (!!accompt && row.label === accompt.label) {
                    setAccompt({ ...accompt, paid_date });
                  } else if (!!ech1 && row.label === ech1.label) {
                    setEch1({ ...ech1, paid_date });
                  } else if (!!ech2 && row.label === ech2.label) {
                    setEch2({ ...ech2, paid_date });
                  }
                }
              }}
              className="form-control form-control-modal-custom form-control-params-custom"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <BootstrapTable
        keyField="uid"
        data={echeances}
        columns={columns}
        bordered={true}
        sort={{ dataField: "date", order: "desc" }}
        condensed={false}
        responsive={true}
        wrapperClasses="table-responsive admin-table"
        noDataIndication={() => "Il ya pas de données"}
      />
      {updated && (
        <div className="row">
          <div className="col-md-8"></div>
          <div className="col-md-4">
            <button
              className={"btn auth-submit-btn"}
              onClick={() => onSubmit()}
              disabled={loading}
            >
              {!loading ? (
                "Enregistrer"
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Enregistrement...</span>
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
