import { Devis, FactureEmise, InvoiceData, UserInfos } from "../../redux/types";

const API_URL = "https://api.fastfacture.com/api";

async function commonGet<T>(url: string) {
  return fetch(API_URL + url, { method: "GET" }).then(
    (res) => res.json() as Promise<T[]>
  );
}

export function getUsersAPi() {
  return commonGet<UserInfos>("/users");
}

export function getInvoicesAPi() {
  return commonGet<InvoiceData>("/invoices");
}

export function getInvoicesByComptableAPi(email: string) {
  return commonGet<InvoiceData>(`/invoices-by-comptable/${email}`);
}

export function getFacturesAPi() {
  return commonGet<FactureEmise>("/factures");
}

export function getFacturesByComptableAPi(email: string) {
  return commonGet<FactureEmise>(`/factures-by-comptable/${email}`);
}

export function getDevisAPi() {
  return commonGet<Devis>("/devis");
}

export function getDevisByComptableAPi(email: string) {
  return commonGet<Devis>(`/devis-by-comptable/${email}`);
}
