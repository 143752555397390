import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { Categorie } from "../../../../redux/types";

type GraphData = { cat: Categorie; count: number };

const DonutChart = ({ data }: { data: GraphData[] }) => {
  const options: ApexOptions = {
    noData: {
      text: undefined,
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 1024,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    labels: data.map((i) => i.cat.name),
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "16px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 700,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
            },
          },
        },
        expandOnClick: true,
      },
    },
    colors: data.map((c) => () => c.cat.color),
  };
  const series = data.map((c, i) => c.count);

  const sumWithInitial = series.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  return (
    <div className="donut">
      {sumWithInitial > 0 ? (
        <Chart
          options={options}
          series={series}
          type="donut"
          width={800}
          height={400}
        />
      ) : (
        <div className="m-4 d-flex justify-content-center">
          <p className="fs-5">Il ya pas de données dans le graphe </p>
        </div>
      )}
    </div>
  );
};

export default DonutChart;
