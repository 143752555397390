import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import Privacy from "../components/Privacy";
import DashboardAdmin from "../components/TableauDebord/Admin/Dashboard/DashboardAdmin";
import FactureDetail from "../components/TableauDebord/Admin/ListeFactures/FactureDetail";
import ListeFactures from "../components/TableauDebord/Admin/ListeFactures/ListeFactures";
import DetailComptable from "../components/TableauDebord/Admin/ListeUsers/DetailComptable";
import DetailUser from "../components/TableauDebord/Admin/ListeUsers/DetailUser";
import ListeComptables from "../components/TableauDebord/Admin/ListeUsers/ListeComptables";
import ListeUsers from "../components/TableauDebord/Admin/ListeUsers/ListeUsers";
import Messagerie from "../components/TableauDebord/Admin/MessageContact/MessageContact";
import ParametreAdmin from "../components/TableauDebord/Admin/ParametreAdmin/ParametreAdmin";
import ProfilAdmin from "../components/TableauDebord/Admin/Profil/ProfilAdmin";
import AdminLayout from "../components/TableauDebord/AdminLayout/AdminLayout";
import DetailUserComptabilite from "../components/TableauDebord/Comptabilite/ListeUsersComptabilite/DetailUserComptabilite";
import ListeReleverComptabilite from "../components/TableauDebord/Comptabilite/ListeUsersComptabilite/ListeReleverComptabilite";
import ListeUsersComptabilite from "../components/TableauDebord/Comptabilite/ListeUsersComptabilite/ListeUsersComptabilite";
import DetailEntreprise from "../components/TableauDebord/SuperAdmin/ListeEntreprisesSuperAdmin/DetailEntreprise";
import Notifications from "../components/TableauDebord/SuperAdmin/Notifications/Notifications";
import DetailParametre from "../components/TableauDebord/SuperAdmin/ParametreSuperAdmin/DetailParametre";
import ParametreSuperAdmin from "../components/TableauDebord/SuperAdmin/ParametreSuperAdmin/ParametreSuperAdmin";
import ForgetPassword from "../components/auth/ForgetPassword";
import Login from "../components/auth/Login";
import ShowDevis from "../components/guest/show-devis";
import ShowFacture from "../components/guest/show-facture";
import { useAppSelector } from "../redux/store";
import { Auth } from "../redux/types";
import { AppStorage } from "../utils/userStorage";

export const ProtectedRoutes = ({ children }: { children: ReactElement }) => {
  const { user, userInfos } = useAppSelector((state) => state.auth);
  const authInfo = AppStorage.getItem<Auth>("auth");

  const isAccess =
    userInfos?.is_admin ||
    userInfos?.is_superadmin ||
    authInfo?.userInfos?.is_admin === true
      ? true
      : false;
  const auth = user?.uid || authInfo?.user?.uid ? true : false;
  return auth && isAccess ? children : <Navigate to="/" replace />;
};

export const ProtectedSuperRoutes = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { user, userInfos } = useAppSelector((state) => state.auth);
  const authInfo = AppStorage.getItem<Auth>("auth");

  const isAccess =
    userInfos?.is_superadmin || authInfo?.userInfos?.is_superadmin === true
      ? true
      : false;
  const auth = user?.uid || authInfo?.user?.uid ? true : false;
  return auth && isAccess ? children : <Navigate to="/" replace />;
};

export const ProtectedComptaRoutes = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { user, userInfos } = useAppSelector((state) => state.auth);
  const authInfo = AppStorage.getItem<Auth>("auth");

  const isAccess =
    userInfos?.is_comptable || authInfo?.userInfos?.is_comptable === true
      ? true
      : false;
  const auth = user?.uid || authInfo?.user?.uid ? true : false;
  return auth && isAccess ? children : <Navigate to="/" replace />;
};

export const AppRoutes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/devis/:id",
    element: <ShowDevis />,
  },
  {
    path: "/facture/:id",
    element: <ShowFacture />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/mot-de-passe-oublie",
    element: <ForgetPassword />,
  },
  {
    path: "admin/",
    element: (
      <ProtectedRoutes>
        <AdminLayout />
      </ProtectedRoutes>
    ),
    children: [
      { element: <Navigate to="/admin/dashboard" replace />, index: true },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "factures",
        element: <ListeFactures />,
      },
      {
        path: "facture/:slug",
        element: <FactureDetail />,
      },
      {
        path: "utilisateurs",
        element: <ListeUsers />,
      },
      {
        path: "comptables",
        element: <ListeComptables />,
      },
      {
        path: "utilisateur/:slug",
        element: <DetailUser />,
      },
      {
        path: "comptable/:slug",
        element: <DetailComptable />,
      },
      {
        path: "parametres",
        element: <ParametreAdmin />,
      },
      {
        path: "administrateur/:slug",
        element: <DetailParametre />,
      },

      {
        path: "profil",
        element: <ProfilAdmin />,
      },
      {
        path: "messagerie",
        element: <Messagerie />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "super-admin/",
    element: (
      <ProtectedRoutes>
        <AdminLayout />
      </ProtectedRoutes>
    ),
    children: [
      {
        element: <Navigate to="/super-admin/dashboard" replace />,
        index: true,
      },
      {
        path: "dashboard",
        element: <DashboardAdmin />,
      },
      {
        path: "utilisateurs",
        element: <ListeUsers />,
      },
      {
        path: "utilisateur/:slug",
        element: <DetailUser />,
      },
      {
        path: "comptable/:slug",
        element: <DetailComptable />,
      },
      {
        path: "comptables",
        element: <ListeComptables />,
      },
      {
        path: "messagerie",
        element: <Messagerie />,
      },
      {
        path: "entreprise/:slug",
        element: <DetailEntreprise />,
      },
      {
        path: "parametres",
        element: <ParametreSuperAdmin />,
      },
      {
        path: "administrateur/:slug",
        element: <DetailParametre />,
      },
      {
        path: "profil",
        element: <ProfilAdmin />,
      },
      {
        path: "factures",
        element: <ListeFactures />,
      },
      {
        path: "facture/:slug",
        element: <FactureDetail />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "comptabilite/",
    element: (
      <ProtectedComptaRoutes>
        <AdminLayout />
      </ProtectedComptaRoutes>
    ),
    children: [
      {
        element: <Navigate to="/comptabilite/utilisateurs" replace />,
        index: true,
      },
      {
        path: "utilisateurs",
        element: <ListeUsersComptabilite />,
      },
      {
        path: "utilisateur/:slug",
        element: <DetailUserComptabilite />,
      },
      {
        path: "releves-bancaires",
        element: <ListeReleverComptabilite />,
      },
      {
        path: "profil",
        element: <ProfilAdmin />,
      },
    ],
  },
];
