import { deleteDoc, doc } from "firebase/firestore";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { AiFillEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { db } from "../../../../firebase";
import { getDateFromString } from "../../../../redux/slice/invoiceSlice";
import { useAppSelector } from "../../../../redux/store";
import { formatAmout } from "../../../../utils/formatter";
import { useReload } from "../../../guest/hooks";
import FacturesTableSkeleton from "../FacturesTable/FacturesTableSkeleton";
import "./Table.css";

export function useInvoices() {
  const invoices = useAppSelector((s) => s.invoices);
  const reload = useReload();
  return {
    invoices,
    loading: invoices.length === 0,
    refetch: () => {
      reload("invoices");
    },
  };
}

function FacturesRecentesTable() {
  const { invoices, refetch, loading } = useInvoices();
  const onDeleteInvoices = async (uid) => {
    const docRef = doc(db, "invoices", uid);
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette facture ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "#02B4C0",
      confirmButtonColor: "#02B4C0",
      preConfirm: () => {
        return deleteDoc(docRef);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result: any) => {
      if (result?.value) {
        await refetch();

        await Swal.fire({
          icon: "success",
          title: `Facture supprimée avec succès!`,
          iconColor: "#02B4C0",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  const actionFormatter: any = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/facture/${cell}`}
              state={row}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
            >
              <AiFillEye />
            </NavLink>
          </div>
          <div className="container-btn-action-icon-modal">
            <button
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={() => onDeleteInvoices(cell)}
            >
              <BsTrashFill />
            </button>
          </div>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "user.nom",
      text: "Société/Nom",
      filter: textFilter({ placeholder: "Chercher..." }),
      headerStyle: () => {
        return { width: "200px" };
      },
    },
    {
      dataField: "user.prenom",
      text: "Agence/Prénom",
      filter: textFilter({ placeholder: "Chercher..." }),
      headerStyle: () => {
        return { width: "200px" };
      },
    },
    {
      dataField: "number",
      text: "N°",
      filter: textFilter({ placeholder: "Chercher..." }),
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      formatter: (cell, row) =>
        getDateFromString(row.date, row?.created_at).format("DD/MM/YYYY"),
      text: "Date",
      filter: textFilter({ placeholder: "Chercher..." }),
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "montant_ttc",
      text: "Montant",
      filter: textFilter({ placeholder: "Chercher..." }),
      formatter: formatAmout,
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    {
      dataField: "uid",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: "130px" };
      },
    },
  ];

  return (
    <>
      {loading && <FacturesTableSkeleton />}
      {!loading && (
        <BootstrapTable
          keyField="uid"
          data={invoices.slice(0, 10)}
          columns={columns}
          bordered={true}
          condensed={false}
          responsive={true}
          wrapperClasses="table-responsive admin-table"
          noDataIndication={() => "Il ya pas de données "}
          filter={filterFactory()}
        />
      )}
    </>
  );
}

export default FacturesRecentesTable;
