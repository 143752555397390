import moment from "moment";
import { useCallback } from "react";
import Swal from "sweetalert2";
import { onReload, Reload } from "../../redux/slice/reload.slice";
import { useAppDispatch } from "../../redux/store";
import {
  BaseTva,
  Devis,
  FactureEmise,
  ModelDevis,
  UserInfos,
} from "../../redux/types";
import {
  addDevisHtml,
  updateDevis,
  updateFacture,
} from "../../utils/api/user.api";
import { uploadDevisFile, uploadFactureFile } from "../../utils/utils";
import { Dev, generateDevis, generateFacture } from "./devis-generator";

export function normaliseTvas(data: BaseTva[]) {
  const finalData: BaseTva[] = [];
  for (let i of data) {
    const index = finalData.findIndex((it) => it.tva === i.tva);
    if (index === -1) {
      finalData.push(i);
    } else {
      let base_ht = getAmount(finalData[index].base_ht);
      let montant_tva = getAmount(finalData[index].montant_tva);
      finalData[index].base_ht = (base_ht + getAmount(i.base_ht)).toFixed(2);
      finalData[index].montant_tva = (
        montant_tva + getAmount(i.montant_tva)
      ).toFixed(2);
    }
  }

  return finalData;
}

export function getNomCom(item: ModelDevis) {
  if (item.nom_commercial) return item.nom_commercial;
  return item.nom_exploitant;
}

export function getAmount(mnt?: string | number) {
  if (!mnt) return 0;
  if (typeof mnt === "number") return mnt;
  if (!mnt.trim()) return 0;
  return parseFloat(mnt.replace(",", "."));
}

export function getLastModel(models: ModelDevis[]) {
  return [...models].sort(
    (a, b) =>
      moment(b.created_at).toDate().getTime() -
      moment(a.created_at).toDate().getTime()
  )[0];
}

export function getDateEcheance(item: Dev) {
  if (item.date_echeance) return moment(item.date_echeance, "DD/MM/YYYY");
  const duree = parseFloat(item?.duree_echeance ?? "");
  let type: moment.DurationInputArg2 = "days";
  if (item.type_duree_echeance === "M") type = "months";
  return moment(item.created_at).add(duree, type);
}

export const DUREE_ITEMS = [
  { label: "Jour", value: "J" },
  { label: "Mois", value: "M" },
];

export const getTotalRemise = (total_ht?: number | string, remise?: string) => {
  total_ht = getAmount(total_ht);
  const total_remise = getAmount(remise);
  return {
    montant: total_remise.toFixed(2),
    total_ht_after_remise: (total_ht - total_remise).toFixed(2),
  };
};

export const onRejectDevis = async (uid: string, onDone: () => void) => {
  await Swal.fire({
    title: `Êtes-vous sûr de vouloir refuser le devis ?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Refuser",
    cancelButtonText: "Annuler",
    showLoaderOnConfirm: true,
    iconColor: "#02B4C0",
    confirmButtonColor: "#02B4C0",
    preConfirm: async () => {
      try {
        await updateDevis(uid, { statut: "Refusé" });
        Swal.fire({
          icon: "success",
          title: `Devis refusé avec succès`,
          iconColor: "#158a91",
          showConfirmButton: false,
          timer: 2000,
        });
        onDone();
      } catch (ex) {
        Swal.fire({
          icon: "error",
          title: `Une erreur est survenue`,
          iconColor: "#02B4C0",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
};

export const onSignDevis = async (
  devis: Devis,
  model: ModelDevis,
  user: UserInfos,
  signature: string,
  onDone: (file: string) => void
) => {
  await Swal.fire({
    title: `Êtes-vous sûr de vouloir signer et accepter le devis ?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Signer et Accepter",
    cancelButtonText: "Annuler",
    showLoaderOnConfirm: true,
    iconColor: "#02B4C0",
    confirmButtonColor: "#158a91",
    preConfirm: async () => {
      try {
        const htmlContent = await generateDevis(devis, model, user, signature);
        await addDevisHtml(devis.id, htmlContent);
        const link = `https://api.fastfacture.com/api/generate-pdf?uid=${devis.id}`;
        const blob = await fetch(link).then((res) => res.blob());
        const file = await uploadDevisFile(blob);
        await updateDevis(devis.id, {
          statut: "Signé",
          signature_client: signature,
          file,
          date_singature: moment().toDate().getTime(),
          date_retractation: moment().add(14, "days").toDate().getTime(),
        });
        Swal.fire({
          icon: "success",
          title: `Devis signé avec succès`,
          iconColor: "#158a91",
          showConfirmButton: false,
          timer: 2000,
        });
        onDone(file);
      } catch (ex) {
        Swal.fire({
          icon: "error",
          title: `Une erreur est survenue`,
          iconColor: "#02B4C0",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
};

export const onSignFacture = async (
  facture: FactureEmise,
  model: ModelDevis,
  user: UserInfos,
  signature: string,
  date: string,
  onDone: (file: string) => void
) => {
  await Swal.fire({
    title: `Êtes-vous sûr de vouloir signer la facture ?`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Signer",
    cancelButtonText: "Annuler",
    showLoaderOnConfirm: true,
    iconColor: "#02B4C0",
    confirmButtonColor: "#158a91",
    preConfirm: async () => {
      try {
        const htmlContent = await generateFacture(
          facture,
          model,
          user,
          signature,
          date
        );
        await addDevisHtml(facture.id, htmlContent);
        const link = `https://api.fastfacture.com/api/generate-pdf?uid=${facture.id}`;
        const blob = await fetch(link).then((res) => res.blob());
        const file = await uploadFactureFile(blob);
        await updateFacture(facture.id, {
          statut: "Signé",
          signature_client: signature,
          file,
          date_singature: moment().toDate().getTime(),
          date_facture: date || moment().format("DD/MM/YYYY"),
        });
        Swal.fire({
          icon: "success",
          title: `Facture signé avec succès`,
          iconColor: "#158a91",
          showConfirmButton: false,
          timer: 2000,
        });
        onDone(file);
      } catch (ex) {
        Swal.fire({
          icon: "error",
          title: `Une erreur est survenue`,
          iconColor: "#02B4C0",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
};

export function useReload() {
  const dispatch = useAppDispatch();

  const reload = useCallback((key: keyof Reload) => {
    dispatch(onReload(key));
  }, []);

  return reload;
}
