import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { AiFillEye } from "react-icons/ai";
import { FactureEmise } from "../../../../redux/types";
import { formatAmout } from "../../../../utils/formatter";
import DetailFactureEmiseComptabiliteModal from "../../../modal/comptabilite/DetailFactureEmiseComptabiliteModal";
import ListeFactureComtabiliteTableSkeleton from "./ListeFactureComtabiliteTableSkeleton";

function ListeFactureEmiseComtabiliteTable({
  invoices,
  isLoading,
  reload,
  userId,
}: {
  invoices: FactureEmise[];
  isLoading: boolean;
  reload: () => any;
  userId?: string;
}) {
  const readOnly = !userId;
  const actionFormatter = (cell, row) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div
            className="modal fade"
            id={`DetailFactureEmiseComptabiliteModal${row.uid}`}
            aria-labelledby="DetailFactureEmiseComptabiliteModalLabel"
            aria-hidden="true"
          >
            <DetailFactureEmiseComptabiliteModal
              facture={row}
              func={reload}
              readOnly={readOnly}
              modalId={`DetailFactureEmiseComptabiliteModal${row.uid}`}
              btnId={`bntFactureComptabiliteModal${row.uid}`}
            />
          </div>

          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#DetailFactureEmiseComptabiliteModal${row?.uid}`}
              id={`bntFactureComptabiliteModal${row.uid}`}
            >
              <AiFillEye />
            </button>
          </div>
        </div>
      </>
    );
  };

  const options = {
    pageStartIndex: 1,
    sizePerPage: 50,
    hidePageListOnlyOnePage: true,
    hideSizePerPage: true,
  };

  const STATUS_OPTIONS = {
    0: "À traiter",
    1: "Validé",
  };

  const columns = [
    {
      dataField: "numero",
      text: "Numéro",
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    // {
    //   dataField: "statut",
    //   text: "Statut",
    //   filter: textFilter({ placeholder: "Chercher..." }),
    // },
    {
      dataField: "statut",
      text: "Statut",
      filterValue: (cell) => (cell === "Soldée" ? 1 : 0),
      filter: selectFilter({
        options: STATUS_OPTIONS,
        placeholder: "Choisir",
      }),
      formatter: (cell: any, row: FactureEmise) => {
        if (row?.statut === "Soldée") {
          return (
            <div className="table-actions-btn-container-statut-facture">
              <p className="statut-facture-valide mb-0">Validé</p>
            </div>
          );
        } else {
          return (
            <div className="table-actions-btn-container-statut-facture">
              <p className="statut-facture-a-traiter mb-0">À traiter</p>
            </div>
          );
        }
      },
    },
    // {
    //   dataField: "numero_client",
    //   text: "N client",
    //   filter: textFilter({ placeholder: "Chercher..." }),
    // },
    {
      dataField: "prenom_client",
      filterValue: (cell, row) => `${row.prenom_client} ${row.nom_client}`,
      text: "Client",
      formatter: (cell: any, row: any) => {
        return `${row.prenom_client} ${row.nom_client}`;
      },
      filter: textFilter({ placeholder: "Chercher..." }),
    },
    {
      dataField: "created_at",
      filterValue: (cell, row) => moment(cell).format("DD/MM/YYYY"),
      text: "Créer le",
      formatter: (cell: any, row: any) => {
        return moment(cell).format("DD/MM/YYYY");
      },
      filter: textFilter({ placeholder: "Chercher..." }),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === "desc") {
          return rowB.created_at - rowA.created_at;
        }
        return rowA.created_at - rowB.created_at;
      },
    },
    {
      dataField: "total_ht",
      text: "Mnt HT",
      formatter: (cell: any, row: any) => {
        return formatAmout(row.total_ht_after_remise);
      },
    },

    {
      dataField: "total_ttc",
      text: "Mnt TTC",
      formatter: (cell: any, row: any) => {
        return formatAmout(row.total_ttc);
      },
    },
    {
      dataField: "date_echeance",
      text: "Échéances",
      formatter: (cell: any, row: any) => {
        if (row.acoumpt || row.echeance1 || row.echeance2) return "OUI";
        return "NON";
      },
    },
    {
      dataField: "id",
      text: "Actions",
      style: { textAlign: "center" },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <ListeFactureComtabiliteTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="uid"
            data={invoices}
            columns={columns}
            bordered={true}
            sort={{ dataField: "date", order: "desc" }}
            condensed={false}
            responsive={true}
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => "Il ya pas de données"}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
          />
        </>
      )}
    </>
  );
}

export default ListeFactureEmiseComtabiliteTable;
