import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FactureEmise } from "../types";

const initialState: FactureEmise[] = [];

export const FacturesSlice = createSlice({
  name: "factures",
  initialState,
  reducers: {
    onFactures: (state, action: PayloadAction<FactureEmise[]>) => {
      const newState: FactureEmise[] = [];
      for (const i of action.payload) {
        newState.push(i);
      }
      return newState.sort((a, b) => b.created_at - a.created_at);
    },
  },
});

export const { onFactures } = FacturesSlice.actions;
