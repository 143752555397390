import moment from "moment";

import {
  Devis,
  DevisFormData,
  FactureEmise,
  FactureEmiseFormData,
  ModelDevis,
  UserInfos,
} from "../../redux/types";
import { formatAmout } from "../../utils/formatter";
import { getAmount, getDateEcheance, getNomCom, normaliseTvas } from "./hooks";

export type DevFact = Devis | FactureEmise;
export type Dev = DevFact | DevisFormData | FactureEmiseFormData;

function minifyHTML(html: string) {
  return html
    .replace(/\n/g, "")
    .replace(/\s+/g, " ")
    .replace(/>\s+</g, "><")
    .replace(/>,</g, "><")
    .replace(/<!--.*?-->/g, "");
}

export async function generateDevis(
  item: Dev,
  model: ModelDevis,
  user: UserInfos,
  signature?: string
) {
  return minifyHTML(getHtml(item, model, user, signature));
}

export async function generateFacture(
  item: FactureEmise,
  model: ModelDevis,
  user: UserInfos,
  signature?: string,
  date?: string
) {
  return minifyHTML(getHtml(item, model, user, signature, true, date));
}

function getOr(cond: boolean, html?: string, or = "") {
  if (cond) return html;
  return or;
}

function getDevisSenderInfosImage(model: ModelDevis) {
  const getEmail = () => {
    if (model.type_email === "Personnalisé") {
      return {
        label: model.libelle_email,
        value: model.email,
      };
    }
    return {
      label: `Mail ${model.type_email}`,
      value: model.email,
    };
  };
  const getPhone = () => {
    if (model.type_telephone === "Personnalisé") {
      return {
        label: model.libelle_telephone,
        value: model.email,
      };
    }
    return {
      label: `N° Tél ${model.type_telephone}`,
      value: model.telephone,
    };
  };
  const email = getEmail();
  const phone = getPhone();

  return `${getOr(
    !!model.logo,
    `<img
            src="${model.logo}"
            style="width: auto; height: 50px;"
          />`
  )}

          <div style="margin-top: 5px;">
            <span style="font-weight: bold">${getNomCom(model)}</span>
          </div>
          ${model.adresss.map(
            (ad, index) =>
              `<div>
            ${getOr(
              index === 0,
              `<span style="font-weight: bold">Adresse : </span>`
            )}
            ${ad}
          </div>`
          )}
          
          <div>
            <span style="font-weight: bold">Code APE : </span>${model.ape}
          </div>
          <div><span style="font-weight: bold">${model.type_siret} : </span>${
    model.siret
  }</div>
          <div>
            <span style="font-weight: bold">${email.label} :</span>
            ${email.value}
          </div>
          <div>
            <span style="font-weight: bold">${phone.label} :</span> ${
    phone.value
  }
          </div>`;
}

function getDevisClientInfos(item: Dev, isFacture?: boolean, date?: string) {
  const date_ec = getDateEcheance(item);

  return `<div>
            <span style="font-weight: bold; font-size: 14px">${
              isFacture ? "FACTURE" : "DEVIS"
            } N°-</span
            ><span style="font-weight: bold; color: #02b4c0">${getOr(
              !!item.numero,
              item.numero
            )}</span>
          </div>

          <div>
            <span style="font-weight: bold">Date ${getOr(
              !!isFacture,
              "de la facture",
              "du devis"
            )} : </span>${date || moment(item.created_at).format("DD/MM/YYYY")}
          </div>
          <div>
            <span style="font-weight: bold">Code client : </span> ${
              item.numero_client
            }
          </div>
          <div>
            <span style="font-weight: bold">Nom client : </span> ${
              item.civilite
            } ${item.prenom_client} ${item.nom_client}
          </div>
          <div>
            <span style="font-weight: bold"> Adresse : </span>${item.adresse}
          </div>
          ${getOr(
            !!item.email_reception,
            `<div>
            <span style="font-weight: bold">Email: </span>
            ${item.email_reception}
          </div>`
          )}
          ${getOr(
            !!item.telephone_reception,
            `
          <div>
            <span style="font-weight: bold">Téléphone : </span>
            ${item.telephone_reception}
          </div>`
          )}
          ${getOr(
            !!isFacture,
            ``,
            `<p style="color: #4a2fd7">Devis valable jusqu'à : ${date_ec.format(
              "DD/MM/YYYY"
            )}</p>`
          )}`;
}

function getDevisProducts(item: Dev) {
  const hasRemise = !!item.donnees?.filter(
    (d) => !!d.montant_remise && getAmount(d.montant_remise) > 0
  )?.length;
  const hasUnite = !!item.donnees?.filter((d) => !!d.unite)?.length;
  return `<table class="table" style="width: 100%">
      <thead>
        <tr>
          <th>Désignation</th>
          <th>Quantité</th>
          ${getOr(hasUnite, `<th>Unité</th>`)}
          <th>Prix unitaire</th>
          <th>Montant HT</th>
          ${getOr(
            hasRemise,
            `<th>Remise</th>
          <th>Montant HT Remisé</th>`
          )}
          <th>% TVA</th>
          <th>Montant TTC</th>
        </tr>
      </thead>
      <tbody>
      ${item?.donnees?.map(
        (it) => `<tr>
                  <td style="width: 30%">
                    ${it.libelle}
                  </td>
                  <td>${it.quantite}</td>
                  ${getOr(hasUnite, `<td>${it.unite ?? "-"}</td>`)}
                  <td>${formatAmout(it.prix_unitaire)}</td>
                  <td>${formatAmout(it.prix_ht)}</td>
                  ${getOr(
                    hasRemise,
                    `<td>${formatAmout(it.montant_remise)}</td>
                  <td>${formatAmout(it.montant_after_remise)}</td>`
                  )}
                  <td>${it.taux_tva}%</td>
                  <td>${formatAmout(it.montant_ttc)}</td>
                </tr>`
      )}
      </tbody>
    </table>`;
}

function getDevisEcheance(item: Dev, model: ModelDevis) {
  const getRow = (it: Devis["acoumpt"] | undefined) => {
    return `<tr>
    <th style="width: 33%">${it?.label ?? ""}</th>
    <td>${it?.date ?? ""}</td>
    <td>${it?.montant ? formatAmout(it.montant) : ""}</td>
  </tr>`;
  };

  return `<table class="table table-not-background">
            <tr>
              <th style="width: 33%"></th>
              <th>Date échéance</th>
              <th>Montant échéance</th>
            </tr>
            ${getRow(item.acoumpt)}
              ${getOr(!!item.echeance1, getRow(item.echeance1))}
              ${getOr(!!item.echeance2, getRow(item.echeance2))}
            
            <tr>
              <td style="border: 0; padding: 5px"></td>
            </tr>
            <tr>
              <td colspan="3" style="border: 0; padding: 0">
                <div
                  class="box"
                  style="text-decoration: underline; text-align: left"
                >
                  <div>
                    Durée des travaux :
                    <span
                      style="color: #4a2fd7; font-weight: bold; font-size: 8px"
                      >${item.duree} ${item.type_duree}</span
                    >
                  </div>
                  <div>
                    Date de debut des travaux :
                    <span
                      style="color: #4a2fd7; font-weight: bold; font-size: 8px"
                      >${item.date_debut}</span
                    >
                  </div>
                </div>
              </td>
              
            </tr>
            <tr>
              <td colspan="3" style="border: 0; padding: 0">
                <div
      class="box"
      style="text-decoration: underline"
    >
      <div>Assurance souscrite au titre de l’activité</div>
      ${getOr(
        !!model.nom_assurance,
        `<div><span style="font-weight: bold">Nom: </span> ${model.nom_assurance}</div>`
      )}
      ${getOr(
        !!model.numero_assurance,
        `<div>
        <span style="font-weight: bold">Numéro: </span> ${model.numero_assurance}
      </div>`
      )}
    </div>
              </td>
            </tr>
          </table>`;
}

function getDevisTva(item: Dev) {
  const tvas = normaliseTvas(item.tva ?? []);
  return `<table class="table table-not-background">
            <tr>
              <th>Base HT</th>
              <th>Taux TVA</th>
              <th>Montant TVA</th>
            </tr>
            ${tvas.map(
              (t) => `<tr>
              <td>${formatAmout(t.base_ht)}</td>
              <td>${t.tva}</td>
              <td>${formatAmout(t.montant_tva)}</td>
            </tr>`
            )}
          </table>`;
}

function getDevisMontant(item: Dev, signature?: string) {
  const hasRemise = !!item.remise && getAmount(item.remise) > 0;
  const hasTva = !!item.total_tva && getAmount(item.total_tva) > 0;
  const renderRow = (title: string, value: string) => `<tr>
              <th>${title}</th>
              <td>${value}</td>
            </tr>`;

  return `<table class="table table-not-background">
           ${renderRow("Total HT", formatAmout(item.total_ht))}
            ${
              hasTva ? renderRow("Total  TVA", formatAmout(item.total_tva)) : ""
            }
            ${hasRemise ? renderRow("Remise", formatAmout(item.remise)) : ""}
            ${
              hasRemise
                ? renderRow(
                    "Total HT remisé",
                    formatAmout(item.total_ht_after_remise)
                  )
                : ""
            }
            ${
              hasTva ? renderRow("Total  TTC", formatAmout(item.total_ttc)) : ""
            }
            ${renderRow("Net payer", formatAmout(item.net_payer))}
            <tr>
              <td style="padding: 0px; border: 0; height: 5px" colspan="2"></td>
            </tr>
            <tr>
              <td style="border: 0; padding: 0" colspan="2">
                <div
                  class="box"
                  style="height: 55px; border-radius: 10px; text-align: center"
                >
                  <div style="text-decoration: underline">
                    Signature du client
                  </div>
                  ${getOr(
                    !!signature,
                    `<img
                    src="${signature}"
                    style="width: auto; height: 45px"
                  />`
                  )}
                </div>
              </td>
            </tr>
          </table>`;
}

export const getHtml = (
  item: Dev,
  model: ModelDevis,
  user: UserInfos,
  signature?: string,
  isFacture?: boolean,
  date?: string
) => {
  return `<html lang="en">
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    <title>Facture</title>
    <title>Document</title>
    ${styles}
  </head>
  <body style="background-color: #fff" style="padding: 20px">
    <table style="background-color: #fff">
      <tr style="vertical-align: top">
        <td
          style="
            width: 55%;
            text-align: left;
            padding-right: 5rem;
            line-height: 20px;
          "
        >
          ${getDevisSenderInfosImage(model)}
        </td>
        <td style="width: 10%"></td>
        <td
          style="
            width: 35%;
            text-align: left;
            padding-right: 1rem;
            line-height: 20px;
          "
        >
          ${getDevisClientInfos(item, isFacture, date)}
        </td>
      </tr>
    </table>

    <p style="color: #4a2fd7; margin-top: 0px">Prestation de services</p>
    ${getDevisProducts(item)}
    <p class="spacing"></p>
    <table style="width: 100%; position: relative; border-spacing: 0">
      <tr>
        <td style="width: 33%">
          ${getDevisEcheance(item, model)}
        </td>
        <td style="width: 2%"></td>
        <td style="width: 33%">
          ${getDevisTva(item)}
        </td>
        <td style="width: 2%"></td>
        <td style="width: 30%">
          ${getDevisMontant(item, signature)}
        </td>
      </tr>
    </table>

    
    <div class="spacing"></div>
    <div
      class="box"
      style="
        margin: 0px 2.5px;
        height: 55px;
        border-radius: 10px;
        text-align: center;
        width: 29%;
        margin-left: 2.5px;
      "
    >
      <div style="text-decoration: underline">Signature électronique</div>
      ${getOr(
        !!user.signature,
        `<img
        src="${user.signature}"
        style="width: auto; height: 45px"
      />`
      )}
    </div>
    ${getOr(
      !!model.tampon,
      `<div
      class="box"
      style="
        margin: 0px 2.5px;
        border-radius: 10px;
        text-align: center;
        width: 33%;
        margin-left: 2.5px;
        border: 0;
      "
    >
      <img
        src="${model.tampon}"
        style="width: auto; height: 40px"
      />
    </div>`
    )}
  </body>
</html>
`;
};

const styles = `<style>
      * {
        font-size: 9px;
        font-family: Poppins;
        page-break-inside: avoid;
      }

      .table {
        border: 1px solid #828282;
        border-collapse: collapse;
        width: 100%;
        border-spacing: 2.5px;
      }
      tr {
        vertical-align: top;
      }
      .table th,
      .table td {
        border: 1px solid #828282;
        padding: 5px;
        text-align: left;
        word-wrap: break-word; /* Legacy CSS */
        overflow-wrap: break-word; /* Modern CSS */
        text-align: center;
        vertical-align: middle;
      }
      .table th {
        background-color: #dadada;
      }
      .table-not-background {
        border: 0;
        border-collapse: separate;
      }
      .table-not-background th,
      .table-not-background td {
        border: 1px solid rgba(218, 218, 218, 1);
        border-radius: 5px;
        padding: 3px 5px;
        height: 25px;
        font-size: 7px;
        vertical-align: middle;
      }
      .table-not-background th {
        background-color: rgba(196, 196, 196, 0.1);
      }
      .table-not-background td {
        background-color: #fff;
      }
      .spacing {
        height: 10px;
        margin: 0;
      }
      .box {
        border: 1px solid rgba(218, 218, 218, 1);
        min-height: 30px;
        border-radius: 10px;
        padding: 15px 7.5px;
      }
    </style>`;
