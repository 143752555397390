import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { InvoiceData } from "../../redux/types";
import { getInvoicesAPi } from "./rest-api";

const firestore = getFirestore();
const functions = getFunctions();

export function invoicesListner(cb: (invoices: InvoiceData[]) => void) {
  getInvoicesAPi().then(cb);
}

export async function updateFactStatutAsync(
  uid?: string,
  data?: Partial<InvoiceData>
) {
  if (!uid) return;
  return await updateDoc(doc(firestore, "invoices", uid), data);
}

export async function sendInvoiceComment({ invoiceId, comment }) {
  const callable = httpsCallable(functions, "sendInvoiceComment");
  const invoiceComment = await callable({ invoiceId, comment });

  return invoiceComment;
}

export async function sendResponseContact({ messageId, response }) {
  const callable = httpsCallable(functions, "sendResponseContact");
  const responseComment = await callable({ messageId, response });

  return responseComment;
}
