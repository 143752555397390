import { useAppSelector } from "../../../../redux/store";
import ExcelExport from "../../../../utils/Excelexport";
import {
  getExportData,
  getExportDataFacture,
} from "../../../../utils/api/user.api";
import { useActiveTab } from "../../../../utils/utils";
import { useInvoices } from "../../../tables/admin/FacturesRecentesTable/FacturesRecentesTable";
import FacturesTable from "../../../tables/admin/FacturesTable/FacturesTable";
import ListeFactureEmiseComtabiliteTable from "../../../tables/comptabilite/ListeFactureComtabiliteTable/ListeFactureEmiseComtabiliteTable";
import "./ListeFactures.css";
const tabs = ["Factures dépenses", "Factures émises"];
const ListeFactures = () => {
  const { invoices, refetch, loading } = useInvoices();
  const factures = useAppSelector((s) => s.factures);
  const categories = useAppSelector((s) => s.categories);
  const { active, handleActiveTab } = useActiveTab();
  return (
    <div className="dashboard-admin-component">
      <div className="container-dashboard-page">
        <div className="admin-page-title-header-container">
          <div className="fast-facture-dash-locataire-requetes-header">
            <h4 className="fast-facture-dash-header-titre">Factures</h4>
          </div>
        </div>
        <div className="col-left-dashboard col-left-dashboard-responsive mb-3">
          <div className="bg-white p-3">
            <ul className="tabs-list px-0 mt-5">
              {tabs?.map((tab, i) => (
                <li
                  className={`tab-item ${active === i ? "active" : ""}`}
                  key={i}
                  onClick={() => handleActiveTab(i)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
            <div className="tabs-content">
              {active === 0 && (
                <>
                  <div className="flex-sb align-items-center mb-4">
                    <div className="tab-dash-admin-filter-container"></div>
                    <ExcelExport
                      getFileData={() => getExportData(invoices, categories)}
                      fileName={`Factures_${new Date().getTime()}`}
                    />
                  </div>
                  <FacturesTable
                    data={invoices}
                    isLoading={loading}
                    func={() => refetch()}
                  />
                </>
              )}
              {active === 1 && (
                <>
                  <div className="flex-sb align-items-center mb-4">
                    <div className="tab-dash-admin-filter-container"></div>
                    <ExcelExport
                      getFileData={() => getExportDataFacture(factures)}
                      fileName={`Factures_${new Date().getTime()}`}
                    />
                  </div>
                  <ListeFactureEmiseComtabiliteTable
                    invoices={factures}
                    isLoading={false}
                    reload={() => refetch()}
                    userId={undefined}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeFactures;
