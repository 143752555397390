import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type Reload = {
  users: string;
  invoices: string;
};
const initialState: Reload = {
  users: "",
  invoices: "",
};

export const ReloadSlice = createSlice({
  name: "reloads",
  initialState,
  reducers: {
    onReload: (state, action: PayloadAction<keyof Reload>) => {
      return { ...state, [action.payload]: Math.random().toString() };
    },
  },
});

export const { onReload } = ReloadSlice.actions;

export default ReloadSlice.reducer;
