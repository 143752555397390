import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import { UserInfos } from "../types";
const initialState: UserInfos[] = [];

export const UsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    onUsers: (state, action: PayloadAction<UserInfos[]>) => {
      const newState: UserInfos[] = [];
      for (let item of action.payload) {
        if (!item.created_at) {
          item.created_at = moment()
            .subtract(1, "year")
            .toDate()
            .getTime()
            .toString();
        }
        newState.push(item);
      }
      return _.sortBy(newState, "created_at").reverse();
    },
  },
});

export const { onUsers } = UsersSlice.actions;
