import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import Logo from "../../assets/appImages/app-logo.png";
import { FactureEmise, ModelDevis, UserInfos } from "../../redux/types";
import { addDevisHtml, factureListner } from "../../utils/api/user.api";
import { generateFacture } from "./devis-generator";
import "./guest.css";
import { getLastModel, onSignFacture } from "./hooks";
const ShowFacture = () => {
  const [doc, setDoc] = useState<{
    facture: FactureEmise;
    model: ModelDevis;
    user: UserInfos;
  }>();
  const ref = useRef<any>(null);
  const params = useParams<{ id: string }>();
  const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
  const getData = (id: string) => {
    setDoc(undefined);
    factureListner(id).then((res) => {
      if (res) {
        if (res.facture.date_facture) {
          setDate(res.facture.date_facture);
        }

        setDoc({
          facture: res.facture,
          model: getLastModel(res.models),
          user: res.user,
        });
      }
    });
  };

  useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, []);
  const isSent = doc?.facture?.statut === "Envoyé";
  // const isSent = true;
  console.log({ date });
  return (
    <div>
      <div className="text-center bg-primary py-3">
        <img src={Logo} alt="Logo" className="logo-app" />
      </div>
      {!doc && (
        <div className="text-center">
          <span
            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
            role="status"
          ></span>
          <span>Chargements</span>
        </div>
      )}
      {!!doc && (
        <div className="container">
          <div className="row mt-2">
            <div className="col-md-4"></div>

            <div className="col-md-4 col-sm-12 my-2">
              <div className="row">
                <div className="col-md-12 col-sm-12 my-2">
                  <p className="my-2">
                    Télécharger la facture{" "}
                    {doc.facture.statut === "Signé" ? "signé" : ""}
                  </p>
                  <button
                    className="btn w-100 text-white bg-primary"
                    type="submit"
                    onClick={async () => {
                      if (!doc.facture.file) {
                        const htmlContent = await generateFacture(
                          doc.facture,
                          doc.model,
                          doc.user,
                          "",
                          date
                        );
                        await addDevisHtml(doc.facture.id, htmlContent);
                      }
                      window.open(
                        `https://api.fastfacture.com/api/generate-pdf?uid=${doc.facture.id}`,
                        "_blank"
                      );
                    }}
                  >
                    Télécharger
                  </button>
                </div>
                {isSent && (
                  <>
                    <div className="col-md-12">
                      <p className="my-2">Signature électronique</p>
                      <input
                        type={"date"}
                        className="form-control auth-form-control"
                        id="date"
                        placeholder="Mot de passe"
                        aria-label="Password"
                        value={moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                        onChange={(e) => {
                          if (e.target.value) {
                            setDate(
                              moment(e.target.value).format("DD/MM/YYYY")
                            );
                          }
                        }}
                        aria-describedby="basic-addon1"
                      />
                      <SignatureCanvas
                        ref={ref}
                        onEnd={(props) => {
                          console.log("props", props);
                        }}
                        penColor="black"
                        canvasProps={{ className: "sigCanvas" }}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <button
                        className="btn btn-outline-primary w-100"
                        type="submit"
                        onClick={(e) => {
                          ref.current.clear();
                        }}
                      >
                        Recommencer
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-2">
                      <button
                        className="btn btn-primary w-100"
                        type="submit"
                        disabled={!date}
                        onClick={() => {
                          const base64 = ref.current
                            .getTrimmedCanvas()
                            .toDataURL("image/png");
                          if (base64) {
                            onSignFacture(
                              doc.facture,
                              doc.model,
                              doc.user,
                              base64,
                              date,
                              () => getData(doc.facture.id)
                            );
                          }
                        }}
                      >
                        Signer
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowFacture;
