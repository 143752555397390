import { useEffect, useState } from "react";
import { getAmount } from "../../components/guest/hooks";
import { FactureEmise, InvoiceData, UserInfos } from "../../redux/types";

const sumHt = (acc: number, cur: InvoiceData) =>
  acc + parseFloat(String(cur.montant_ht));
const sumTva = (acc: number, cur: InvoiceData) =>
  acc + parseFloat(String(cur.tva));

export function useDashboard(
  users: UserInfos[],
  invoices: InvoiceData[],
  factures: FactureEmise[]
) {
  const [data, setData] = useState({
    total_tva_emise: 0,
    total_tva_recuperable: 0,
    total_facture_emise: 0,
    total_facture_depense: 0,
    total_user_count: 0,
    total_facture_count: 0,
  });

  useEffect(() => {
    const finalData = {
      total_tva_emise: 0,
      total_tva_recuperable: 0,
      total_facture_emise: 0,
      total_facture_depense: 0,
      total_user_count: 0,
      total_facture_count: 0,
    };
    let deps: InvoiceData[] = [];
    let emises: InvoiceData[] = [];

    for (let i of invoices) {
      if (i.type_depense === "Émise") emises.push(i);
      else deps.push(i);
    }

    type TotalEmise = {
      total_factures_emises: number;
      total_tva_emise: number;
    };
    const { total_factures_emises, total_tva_emise } = factures.reduce(
      (acc: TotalEmise, cur: FactureEmise) => {
        if (cur.statut !== "En cours" && cur.statut !== "Litige") {
          return {
            total_factures_emises:
              acc.total_factures_emises + getAmount(cur.total_ht_after_remise),
            total_tva_emise: acc.total_tva_emise + getAmount(cur.total_tva),
          };
        }
        return acc;
      },
      { total_factures_emises: 0, total_tva_emise: 0 }
    );

    finalData.total_tva_emise = emises.reduce(sumTva, 0) + total_tva_emise;
    finalData.total_tva_recuperable = deps.reduce(sumTva, 0);
    finalData.total_facture_emise =
      emises.reduce(sumHt, 0) + total_factures_emises;
    finalData.total_facture_depense = deps.reduce(sumHt, 0);
    finalData.total_user_count = users.length;
    finalData.total_facture_count = invoices.length;
    setData(finalData);
  }, [users.length, invoices.length, factures.length]);

  return {
    data,
    loading: data.total_facture_count === 0,
  };
}
